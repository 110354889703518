<template>
  <div class="detail-box">
    <div class="detail-nav">
      <div class="nav-box">
        <span
          ><span
            ><a href="#/" class="nav-title router-link-active">首页</a>
          </span></span
        ><span
          ><span class="nav-title"> <span>&gt;</span>其他服务</span></span
        >
        <span
          ><span
            ><span>&gt;</span><a :href="`#/list/sentiment?columnId=${$route.query.columnId}`" class="nav-title">上市公司资讯</a></span
          ></span
        >
      </div>
    </div>
    <div class="detail-contents">
      <div class="article-title">
        <h3 class="title-text">{{ detailMsg.title }}</h3>
        <div class="article-source">
          <p class="source-msg">
            <template> 来源：{{ detailMsg.externalSource }} </template>
            {{ detailMsg.publishTime }}
          </p>
          <p class="share-article">
            <span class="share-tip">分享到：</span>
            <a id="share_weibo" class="weibo" @click="shareSina">
              <img
                class="share_icon"
                src="@/assets/img/detailImg/weibo.svg"
                alt="weibo"
              />
              <img
                class="share_icon active_icon"
                src="@/assets/img/detailImg/weibo_active.svg"
                alt="weibo"
              />
            </a>
            <a id="share_wechat" class="wechat">
              <img
                class="share_icon"
                src="@/assets/img/detailImg/wechat.svg"
                alt="wechat"
              />
              <img
                class="share_icon active_icon"
                src="@/assets/img/detailImg/wechat_active.svg"
                alt="wechat"
              />

              <span
                id="wechat_qrcode"
                class="wechart-qrcode"
                ref="wechartQr"
              ></span>
            </a>
            <a id="share_qq" class="qq" @click="shareQQ"
              ><!--  -->
              <img
                class="share_icon"
                src="@/assets/img/detailImg/qq.svg"
                alt="qq"
              />
              <img
                class="share_icon active_icon"
                src="@/assets/img/detailImg/qq_active.svg"
                alt="qq"
              />
              <span id="qq_qrcode" class="qq-qrcode" ref="qqQr"></span>
            </a>
          </p>
        </div>
      </div>
      <div class="simple-text" v-if="detailMsg.subTitle">
        {{ detailMsg.subTitle }}
      </div>
      <div class="article-text" v-html="detailMsg.content"></div>
    </div>
  </div>
</template>
<script>
// import '@/assets/css/other-detail'
// import axios from "axios";
import "@/assets/css/other-detail.less";
import QRCode from "qrcodejs2";
import { sentimentFind } from "@/api/list";
import { setCatalogTree } from "@/utils/store-data";
import BuryingPont from "@/mixins/buryingPoint.js";
export default {
  mixins: [BuryingPont],
  data() {
    return {
      detailMsg: {},
    };
  },
  created() {
    setCatalogTree(this.$store);
    // 详情
    var detailData = {
      contentID: this.$route.query.contentID,
    };
    sentimentFind(detailData)
      .then((res) => {
        const detailMsg = res.data[0];
        detailMsg.content = detailMsg.content
          .replace(/\n /g, "<br/>")
          .replace(new RegExp(/( )/g), "&nbsp;");
        this.detailMsg = detailMsg;
          // 浏览详情页埋点
          this.sensors.track( 'LY_view_article_page' ,{
            AppName: this.$store.state.AppName,
            page_id: '',
            content_sources: this.detailMsg.externalSource,
            release_time: this.detailMsg.publishDate,
            title: this.detailMsg.title,
            content_id: this.detailMsg.id,
            reading_quantity: this.readNum,
            is_login: false,
            platform: this.$store.state.platform,
          })
      })
      .catch(() => {});
  },
  mounted() {
    this.creatQrCode();
  },
  methods: {
    // 分享到新浪微博
    shareSina() {
      this.sinaWeiBo(this.detailMsg.title);
    },
    sinaWeiBo(title, url, pic) {
      var param = {
        url: url || window.location.href,
        type: "3",
        count: "1" /** 是否显示分享数，1显示(可选)*/,
        appkey: "" /** 您申请的应用appkey,显示分享来源(可选)*/,
        title: title /** 分享的文字内容(可选，默认为所在页面的title)*/,
        pic: pic || "" /** 分享图片的路径(可选)*/,
        ralateUid: "" /** 关联用户的UID，分享微博会@该用户(可选)*/,
        rnd: new Date().valueOf(),
      };
      var temp = [];
      for (var p in param) {
        temp.push(p + "=" + encodeURIComponent(param[p] || ""));
      }
      var targetUrl =
        "http://service.weibo.com/share/share.php?" + temp.join("&");
      window.open(targetUrl, "sinaweibo", "height=430, width=400");
    },
    // 微信分享二维码
    creatQrCode() {
      new QRCode(this.$refs.wechartQr, {
        text: window.location.href.replace("/ly/#/", "/mly/#/").replace("list/sentiment-detail", "pages/other-service/other-listed-company-detail"), // 需要转换为二维码的内容
        width: 128,
        height: 128,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });

      // new QRCode(this.$refs.qqQr, {
      //     text: window.location.href, // 需要转换为二维码的内容
      //     width: 128,
      //     height: 128,
      //     colorDark: '#000000',
      //     colorLight: '#ffffff',
      //     correctLevel: QRCode.CorrectLevel.H
      // });
    },
    //分享到QQ
    shareQQ() {
      // var shareUrl = null;
      // var that = this;
      // axios({//格式a
      //   method:'get',
      //   url:'https://api.985.so/api.php?format=json&url='+encodeURIComponent(window.location.href)+'&apikey=15928642493@4ab8df110bb634c40a638f973df88500'
      // }).then(function(resp){
      //   console.log(resp.data.url);
      //   shareUrl = resp.data.url;
      //   that.qqShare(that.detailMsg.title,shareUrl)
      // }).catch(resp => {
      //   console.log('请求失败：'+resp.status+','+resp.statusText);
      // });
      var shareUrl = window.location.href.replace("/ly/#/", "/mly/#/").replace("list/sentiment-detail", "pages/other-service/other-listed-company-detail");
      this.qqShare(this.detailMsg.title, shareUrl);
    },
    // http://api.985.so/api.php
    qqShare: function (title, url, pic) {
      var param = {
        url: url || window.location.href,
        desc: "" /*分享描述*/,
        title: title || "" /*分享标题(可选)*/,
        summary: "" /*分享描述(可选)*/,
        pics: pic || "" /*分享图片(可选)*/,
        flash: "" /*视频地址(可选)*/,
        site: "" /*分享来源 (可选) */,
      };
      var s = [];
      for (var i in param) {
        if (i == "url") {
          s.push(i + "=" + encodeURIComponent(param[i] || ""));
        } else {
          s.push(i + "=" + (param[i] || ""));
        }
      }
      var targetUrl =
        "http://connect.qq.com/widget/shareqq/index.html?" + s.join("&");
      window.open(targetUrl, "qq", "height=520, width=720");
    },
  },
};
</script>

