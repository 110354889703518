import request from "@/utils/request";

/**
 * 获取文章列表
 * @param {*} data 
 */
export function otherList(data) {
  return request({
    url: "/f/front/article/articleList",
    method: "post",
    data
  });
}
// 文章阅读量
export function getClickVolume(params) {
  return request({
    url: "/f/front/article/getClickVolume",
    method: "get",
    params
  });
}
/**
 * 
 * @param {*} data 
 */
export function otherDetail(data) {
  return request({
    url: "/f/front/article/articleDetail",
    method: "post",
    data
  });
}
/**
 * 获取投教的上市公司资讯
 * @param {*} data 
 */
 export function sentimentFind(data) {
  return request({
    url: "/f/cnfic/xhcj/sentiment/find",
    method: "post",
    data: data
  });
}